
import { Component, Vue } from "vue-property-decorator";
import OrderPane from "../components/order-pane.vue";
import DatePicker from "@/components/date-picker.vue";
import { apiOrderLists, apiOtherLists } from "@/api/order";
import ExportData from "@/components/export-data/index.vue";
import { RequestPaging } from "@/utils/util";
import { OrderType } from "@/utils/type";
@Component({
  components: {
    OrderPane,
    DatePicker,
    ExportData,
  },
})
export default class Order extends Vue {
  // S Data
  activeName: any = "all"; //全部;

  apiOrderLists = apiOrderLists;

  tabs = [
    {
      label: "全部",
      name: "all",
    },
    {
      label: "待付款",
      name: OrderType[0],
    },
    {
      label: "已完成",
      name: OrderType[1],
    },
  ];

  index = 0;

  pager = new RequestPaging();

  tabCount = {
    all: 0, //全部
    wait: 0, //待支付
    finish: 0, //已关闭
  };

  form:any = {
    sn: "", //否	string	订单信息
    shop_name: "", //否	string	团购名称
    set_meal_id: "", //否	string	套餐id
    pay_status: "", //否	string	支付状态 0 未支付 1-已支付
    time_type: "", //否	string	时间类型:create_time-下单时间;pay_time-支付时间
    pay_way: "", // 支付方式
    start_time: "", //否	string	开始时间
    end_time: "", //否	string	结束时间
  };

  otherLists: any = {
    set_meal: [],
    pay_status: [],
    pay_way: [],
  };
  // E Data

  // S Methods

  // 获取订单信息
  getOrderLists(page?: number) {
    page && (this.pager.page = page);
    if (this.form.start_time != "" || this.form.end_time != "") {
      if (this.form.time_type == "") {
        return this.$message.error("选择时间必须选择时间类型!");
      }
    }

    // let status: any =
    //   this.activeName == "all" ? "" : OrderType[this.activeName];
    this.form.order_status = this.activeName == "all" ? "" : OrderType[this.activeName];
    this.pager
      .request({
        callback: apiOrderLists,
        params: {
          // order_status: status,
          ...this.form,
        },
      })
      .then((res) => {
        this.tabCount = res?.extend;
      });
  }

  // 重置搜索
  reset() {
    Object.keys(this.form).map((key) => {
      this.$set(this.form, key, "");
    });
    this.getOrderLists();
  }

  // 获取订单其他 方式数据
  getOtherMethodList() {
    apiOtherLists().then((res: any) => {
      this.otherLists = res;
    });
  }
  // E Methods

  // S  life cycle

  created() {
    // 获取订单信息
    this.getOrderLists();
    // 获取其他方式数据
    this.getOtherMethodList();
  }

  // E life cycle
}
