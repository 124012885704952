
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { apiOrderConfirmPay } from '@/api/order'
import MaterialSelect from "@/components/material-select/index.vue";
@Component({
    components: {
        LsDialog,
        LsPagination,
        MaterialSelect
    }
})
export default class UploadVoucher extends Vue {
    @Prop() value!: boolean
    @Prop({ required: true }) orderId!: number
    $refs: any
    formData = {
        voucher: []
    }
    rules = {
        voucher: [{
            type: 'array', required: true, message: '请选择支付凭证', trigger: 'change'
        }]
    }
    get visible() {
        return this.value
    }
    set visible(value) {
        this.$emit('input', value)
    }
    close() {
        this.formData.voucher = []
        this.visible = false
    }
    async handleConfirm() {
        await this.$refs.formRef.validate()
        await apiOrderConfirmPay({
            set_meal_order_id: this.orderId,
            voucher: this.formData.voucher
        })
        this.close()
        this.$emit('success')
    }
}
